import { GetDocEndPoint } from "./endpoints";

export const GetDocByID=(DocID)=>{

    var Body=JSON.stringify({DocId:DocID})
      var myHeaders = new Headers();
    
      myHeaders.append("Content-Type", "application/json");
    
      var raw = Body;
    
      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };
    
      return fetch(GetDocEndPoint, requestOptions)
        .then((response) => response.json())
        .then((result) => {
          return result;
        })
        .catch((error) => {
          console.log(error)
          return null;
        });
    
    }