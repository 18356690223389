import React, { useEffect, useState } from 'react'
import { NotFound } from '../Components/Global/notFound'
import { GetDocByID } from '../Utils/apicalls';
import { DecryptVal } from '../Utils/Encryption';
import PDFIcon from '../Assets/Images/pdf.png'
import TXTIcon from '../Assets/Images/txt.png'
import { Loader } from '../Components/loader';
import HTMLIcon from '../Assets/Images/html.png'
import { html2pdf } from 'html2pdf.js';
import jsPDF from 'jspdf';


export const Documents = () => {
const [isLoading, setIsLoading] = useState(true)
const [HtmlContent, setHtmlContent] = useState("<h1>Here</h1>")
const [SavedContent, setSavedContent] = useState("")
const [Found, setFound] = useState(false)
useEffect(() => {
  
  const urlParams = new URLSearchParams(window.location.search);

  var DocID = urlParams.get("docx");
  console.log(DocID)
  
  if(DocID!==null)
      {DocID=DecryptVal(DocID)}
      
  var Resp=GetDocByID(parseInt(DocID))
Resp.then((Data)=>{
  if(Data!==null){
      if(Data.Status==="Success"){
          setSavedContent(Data.DocDetails)
          setIsLoading(false)
          setFound(true)
      }
      else{
        setIsLoading(false)
          setFound(false)
     
  }
}
})

}, [])

useEffect(() => {
  if(SavedContent.ContentInfo!==undefined){
    var Content = SavedContent.ContentInfo.replace(/<img[^>"']*((("[^"]*")|('[^']*'))[^"'>]*)*>/g,"");   
    setHtmlContent(Content)
  }


}, [SavedContent])


const downloadAsPDF = () => {
  setTimeout(() => {
    const element = document.getElementById("PDFCont"); 

    const options = {
      margin: 10,
      filename: "Zomani-"+SavedContent.ContentName+".pdf",
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
    };

    html2pdf().from(element).set(options).save();
   

  }, 500);
};
const downloadTxtFile = () => {
  var TextContent= SavedContent.ContentInfo.replace(/<[^>]*(>|$)|&nbsp;|&zwnj;|&raquo;|&laquo;|&gt;/g, '');

  const element = document.createElement("a");
  const file = new Blob([TextContent], {type: 'text/plain'});
  element.href = URL.createObjectURL(file);
  element.download = "Zomani-"+SavedContent.ContentName+".txt";
  document.body.appendChild(element); // Required for this to work in FireFox
  element.click();


}
const downloadHtmlFile = () => {
  const element = document.createElement("a");
  const file = new Blob([SavedContent.ContentInfo ], {type: 'text/plain'});
  element.href = URL.createObjectURL(file);
  element.download = "Zomani-"+SavedContent.ContentName+".html";
  document.body.appendChild(element); // Required for this to work in FireFox
  element.click();

}
  return (
    <div className='documentBody'>
      <div className='container'>
      {isLoading?

    <Loader/>  :
Found?
<div className='p-5'>
<div className="text-center d-flex DownloadDiv">
          
            {/* <img className="DownIcon" title="Download as PDF" src={PDFIcon} onClick={downloadAsPDF}></img> */}
          
    
               <img className="DownIcon" title="Download As Plain Text" src={TXTIcon} onClick={downloadTxtFile}></img>
             
            <img className="DownIcon" title="Download As HTML" src={HTMLIcon} onClick={downloadHtmlFile}></img>
            
          </div>
  <h2>{SavedContent.ContentName}</h2>
<div
className="RenderDoc"

dangerouslySetInnerHTML={{ __html: SavedContent.ContentInfo }}
></div></div>
:
<NotFound/>
    }




      </div>
      <div className="d-none">
        <div
          className=""
          id={"PDFCont"}
          dangerouslySetInnerHTML={{ __html: HtmlContent }}
        ></div>
      </div>
    </div>
  )
}
