import React from 'react'
import notfound from '../../Assets/Images/no-results.png'
export const NotFound = () => {
  return (
    <div className='notFound'>
        <div className=''>

<img src={notfound} height={350} width={350}></img>
<h3>Document not found</h3>
<p>Plase make sure the link is correct</p>
        </div>


    </div>
  )
}
