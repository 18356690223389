import React from 'react'
import "../Assets/Loading.css"
export const Loader = () => {
  return (
    
    <div className="Loader-Body">
      <div className="">
  
        <div className="lds-ring"><div></div><div></div><div></div><div></div></div>
      </div>

    </div>
  )
}
