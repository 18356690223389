import './App.css';
import { Documents } from './Screens/Documents';
import { Header } from './Components/Global/header';
import { Footer } from './Components/Global/footer';
function App() {
  return (
    <>
    <Header/>
    <Documents/>
    <Footer/>
  </>);
}

export default App;
