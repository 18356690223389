import React from 'react'
import Logo from '../../Assets/Images/logow.png'
export const Header = () => {
  return (
    <div className='HeaderBody'>
<div className='container'>
    <div className='row'>
      <div className='col-9'>
<div className='LogoSection'>
  <img src={Logo}></img>
</div>

      </div>
      <div className='col-lg-3'>
        <div className=''>
       <a href='https://app.zomani.ai' target='_blank'> <button className='CTA'>Continue with Zomani</button></a>
        </div>
      </div>
    </div>
    </div>
    </div>
  )
}
