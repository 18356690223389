import React from 'react'
import Logo from '../../Assets/Images/logow.png'
export const Footer = () => {
  return (
    <div className='FooterBody'>
<div className='container'>

<div className='row'>

  <div className='col-lg-4 mb-4'>
  <div className='LogoSection mb-2'>
  <img src={Logo}></img>

</div>
<h5>
  Powered by <strong>Apex Cloud Technologies</strong>
</h5>

  </div>
  <div className='col-lg-3'>
    <div className='Footer-Links'>
      <h3>Tools</h3>
    <ul>
<li>AI Art Generation</li>
<li>PDF Downloading</li>
<li>Book Writing</li>
<li>AI Score and Detection</li>
<li>Drip Campaigns</li>
<li>Chrome Extension</li>

    </ul>



    </div>


  </div>
  <div className='col-lg-3'>
  <div className='Footer-Links'>
      <h3>Support</h3>
    <ul>
 <li>   FAQs</li>
 <li> Contact Us</li>
 <li> Terms & Condition</li>
 <li> Privacy Policy</li>

    </ul>



    </div>
  </div>
  <div className='col-lg-2'>
  <div className='Footer-Links'>
      <h3>Company</h3>
    <ul>
    <li>  About Zomani</li>
    <li> Blogs & Articles</li>
    <li> Use Cases</li>
    <li> Pricing</li>

    </ul>



    </div>
  </div>

</div>
<div className='row mt-5'>

<div className='col-lg-6'>
<div className='SocialIcons'>
<i className="fa-brands fa-facebook"></i>
<i className='fa-brands fa-twitter'></i>
<i className='fa-brands fa-linkedin'></i>
<i className='fa-brands fa-instagram'></i>
</div>
</div>
  <div className='col-lg-6'>
    <div className='Copyright'>
    Copyright © 2023  <a href='https://zomani.ai' rel='no-follow'> Zomani</a>, All rights reserved.
    </div>
  </div>
</div>

</div>

    </div>
  )
}
